import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import logo from '../images/logo.png';
import { useNavigate } from "react-router-dom";
import Context from '../context/Context';

const pages = ['Home', 'Our Menu', 'Our Coffee', 'Services', 'Contact Us'];
const links = {'Home': '/Home', 'Our Menu': '/OurMenu', 'Our Coffee': '/OurCoffee', 
'Contact Us': '/ContactUs', 'Services': '/Services'};

function TopBar() {
  const ctx = React.useContext(Context);
  const navigate = useNavigate();
  const [anchorElNav, setAnchorElNav] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const changelink = (oneclicked) => {
    // console.log(oneclicked);
    navigate(links[oneclicked]);
    handleCloseNavMenu();
  }

  return (
    <AppBar position="static" sx={{ backgroundColor: '#DFCAA0' }}>
      <Container maxWidth="100vw">
        <Toolbar disableGutters>
            <img
                src={logo}
                alt="Logo"
                style={{
                display: { xs: 'none', md: 'flex' },
                marginRight: '1rem', // Adjust spacing as needed
                width: 'auto',
                height: '5rem', // Adjust height as needed
                marginTop: '0'
                }}
                onClick={() => changelink("Home")}
            />
          <Typography
            variant="h6"
            noWrap
            component="a"
            sx={{
              mr: 2,
              display: ctx.showAppButtons() ? 'flex' : 'none',
              fontWeight: 700,
              color: '#5C4033',
              letterSpacing: '.3rem',
              textDecoration: 'none',
              fontSize: '1.8vw',
            }}
          >
            Perfect Pour Espresso Catering
          </Typography>
          <Typography
            variant="h5"
            component="a"
            sx={{
              mr: 2,
              display: ctx.showAppButtons() ? 'none' : 'flex',
              flexGrow: 1,
              fontWeight: 700,
              letterSpacing: '.3rem',
              color: '#5C4033',
              textDecoration: 'none',
              fontSize: { xs: '3vw', md: '3vw'}
            }}
          >
            Perfect Pour Espresso Catering
          </Typography>
          {/* <Box sx={{ flexGrow: 1, display: {xs: 'none', sm: 'none', md: displayCondition() ? 'flex' : 'none', lg: 'flex' }, justifyContent: "flex-end"}}> */}
          <Box sx={{ flexGrow: 1, display: ctx.showAppButtons() ? 'flex' : 'none', justifyContent: "flex-end"}}>
            {pages.map((page) => (
                <Button
                key={page}
                onClick={() => changelink(page)}
                sx={{ my: 2, display: 'block' }}
                >
                    {page}
                </Button>
            ))}
          </Box>
          <Box sx={{ flexGrow: 1, display: ctx.showAppButtons() ? 'none' : 'flex', justifyContent: "flex-end"}}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="#5C4033"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                '.MuiPaper-root': { // Target the Paper component
                  //backgroundColor: '#DFCAA0', // Not sure if I like how this looks, for now will keep white
                },
                display: { xs: 'block', sm: 'block', md: 'block', lg: 'none'}
              }}
            >
              {pages.map((page) => (
                <MenuItem key={page} onClick={() => changelink(page)}>
                  <Typography textAlign="center"sx={{color: '#5C4033'}}>{page}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default TopBar;
