import React from 'react';
import { Box, Typography, Divider, Grid, Button} from '@mui/material';
import menuImage from '../images/Menu.jpg'; // Replace with the path to your image
import { styled } from '@mui/system';
import Bottom from './Bottom';
import { useNavigate } from 'react-router-dom';
const Image = styled('img')({
  width: '100%',
  height: 'auto',
});
const OurMenu = () => {
  const navigate = useNavigate();

  const handleContactClick = () => {
    navigate('/ContactUs');
  };

  return (
    <div>
      <Box sx={{ padding: '20px', textAlign: 'center' }}>
        <div style={{ width: '100%', textAlign: 'center', marginTop: '20px', display: 'flex', alignItems: 'center', justifyContent: 'center', overflow: 'hidden'}}>
              <Divider style={{ width: '50vw', backgroundColor: '#5C4033', height: '2px' }} />
              <Typography variant="h2" component="h2" sx={{color: '#5C4033', fontSize: '40px', margin: '0 10px', whiteSpace: 'nowrap'}}>
                Our Menu
              </Typography>
              <Divider style={{ width: '50vw', backgroundColor: '#5C4033', height: '2px' }} />
        </div>
        <Box
          component="img"
          src={menuImage}
          alt="Current Menu"
          sx={{ width: '80%', marginTop: '20px', objectFit: 'cover' }}
        />

        <div style={{ width: '100%', textAlign: 'center', marginTop: '20px', marginBottom: '20px', display: 'flex', alignItems: 'center', justifyContent: 'center', overflow: 'hidden'}}>
          <Divider style={{ width: '50vw', backgroundColor: '#5C4033', height: '2px' }} />
          <Typography variant="h2" component="h2" sx={{color: '#5C4033', fontSize: '30px', margin: '0 10px', whiteSpace: 'nowrap'}}>
            Additional Items We Offer
          </Typography>
          <Divider style={{ width: '50vw', backgroundColor: '#5C4033', height: '2px' }} />
        </div>

        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <Typography variant="body1" paragraph sx={{color: '#5C4033'}} >
              Our menu is fully customizable with a variety of additional options to suit your preferences. We also provide a range of milk alternatives to cater to different dietary needs, ensuring everyone can enjoy their perfect cup.
            </Typography>
            <Typography variant="body1" paragraph sx={{color: '#5C4033'}} >
              - Drip Coffee: Freshly brewed coffee made from our select beans, perfect for a quick and flavorful cup.
            </Typography>
            <Typography variant="body1" paragraph sx={{color: '#5C4033'}} >
              - Milk Alternatives: Almond milk, soy milk, oat milk, and more to accommodate various dietary preferences.
            </Typography>
            <Typography variant="body1" paragraph sx={{color: '#5C4033'}} >
              - Additional Coffee Options: A wide selection of espresso-based drinks, cold brews, and specialty coffees are available to delight your taste buds.
            </Typography>
            <Typography variant="body1" paragraph sx={{color: '#5C4033', marginTop: '20px'}}>
              Contact us today to create your perfect menu tailored to your event’s needs and preferences.
            </Typography>
            <Button 
              variant="contained" 
              sx={{ backgroundColor: '#5C4033', marginTop: '10px' }}
              onClick={handleContactClick}
            >
              Contact Us
            </Button>
          </Grid>
          <Grid item xs={12} md={6}>
            <Image
              src={require("../images/Green.jpg")}
              alt="About Us"
              style={{ width: '100%', height: '400px', cursor: 'pointer', objectFit: 'cover' }}
            />
          </Grid>
        </Grid>
      
      </Box>
      <Bottom/>
    </div>
  );
};

export default OurMenu;
