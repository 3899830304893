import React from 'react';
import { BrowserRouter, Routes, Route, Navigate} from "react-router-dom";
import Home from './components/Home';
import OurMenu from './components/OurMenu';
import OurCoffee from './components/OurCoffee';
import TopBar from './components/TopBar';
import ContactUs from './components/ContactUs';
import Services from './components/Services'
import Provider from './context/Provider';
import {ThemeProvider} from '@emotion/react';
import {createTheme} from '@mui/material/styles';
const Roots = () => {

    const theme = createTheme({
        typography: {
            fontFamily: 'Playfair Display, serif', // Set font to Playfair Display
            // Optionally, you can set other typography options here
          },
          palette: {
            primary: {
              main: '#5C4033', // Set color to #5C4033
            },
            // Optionally, you can set other palette colors here
          },
    });
    return (
        <BrowserRouter>
            <div>
                <ThemeProvider theme={theme}>
                    <Provider>
                        <TopBar/>
                        <Routes>
                            <Route path="/Home" element={<Home/>} />
                            <Route path="/OurMenu" element={<OurMenu/>}/>
                            <Route path="/OurCoffee" element={<OurCoffee/>}/>
                            <Route path="/ContactUs" element={<ContactUs/>}/>
                            <Route path="/Services" element={<Services/>}/>
                            <Route path="*" element={<Navigate to="/Home" />} />
                        </Routes>
                    </Provider>
                </ThemeProvider>
            </div>

            
        </BrowserRouter>
    );
};

export default Roots;
