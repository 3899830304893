import './App.css';
import React from 'react';
import Roots from './Routes';

const App = () => {
  return (
    <div>
      <Roots/>
    </div>
  );
};

export default App;