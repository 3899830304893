import React from 'react';
import { Container, Typography, Box, Grid, Button, Paper, Divider} from '@mui/material';
import { styled } from '@mui/system';
import heroImageMobile from '../images/IMG_8646.png';
import heroImageDesktop from '../images/IMG_8646copy.png';
import Context from '../context/Context';
import { useNavigate } from "react-router-dom";
import Bottom from './Bottom';

const Hero = styled(Paper)(({ theme }) => ({
  position: 'relative',
  color: '#fff',
  marginBottom: theme.spacing(4),
  
  backgroundSize: 'cover', // Default size for larger screens
  backgroundPosition: 'center',

  [theme.breakpoints.down('md')]: {
    height: '400px',
    backgroundImage: `url(${heroImageMobile})`, // Adjust for smaller screens
  },
  
  [theme.breakpoints.up('md')]: {
    height: '650px',
    backgroundImage: `url(${heroImageDesktop})`, // Adjust for medium and larger screens
  }
}));


const Overlay = styled(Box)({
  position: 'absolute',
  top: 0,
  bottom: 0,
  right: 0,
  left: 0,
  backgroundColor: 'rgba(0,0,0,.3)',
});

const HeroContent = styled(Box)(({ theme }) => ({
  position: 'relative',
  padding: theme.spacing(6),
  paddingRight: 0,
}));

const Section = styled(Box)(({ theme }) => ({
  padding: theme.spacing(8, 0),
}));

const Image = styled('img')({
  width: '100%',
  height: 'auto',
});


const Home = () => {
  const changelink = (oneClicked) => {
    const options = ['/OurMenu', '/OurCoffee', '/Services']
    navigate(options[oneClicked]);
  }
  const navigate = useNavigate();
  const ctx = React.useContext(Context);
  return (
    <div>
      <Container>
        <Hero sx={{marginTop: "20px"}}>
          <Overlay />
          <HeroContent>
            {ctx.currWidth() > 600 ? (<Typography variant="h2" component="h1" gutterBottom>
              Elevate Your Occasion
            </Typography>) : (<Typography variant="h3" component="h1" gutterBottom>
              Elevate Your Occasion
            </Typography>)}
            <Typography variant="h5" paragraph>
              Bringing the best coffee experience to your events
            </Typography>
            <Button
              variant="contained"
              sx={{
                backgroundColor: '#5C403',
                '&:hover': {
                  backgroundColor: '#4A332',
                },
              }}
            >
              Learn More
            </Button>
          </HeroContent>
        </Hero>
        
        <Section>
          <div style={{ width: '100%', textAlign: 'center', marginTop: '-40px', display: 'flex', alignItems: 'center', justifyContent: 'center', overflow: 'hidden'}}>
            <Divider style={{ width: '50vw', backgroundColor: '#5C4033', height: '2px' }} />
            <Typography variant="h4" component="h2" sx={{color: '#5C4033', fontSize: '30px', margin: '0 10px', whiteSpace: 'nowrap'}}>
              Our Offerings
            </Typography>
            <Divider style={{ width: '50vw', backgroundColor: '#5C4033', height: '2px' }} />
          </div>
        
          <Grid container spacing={4}>
            <Grid item xs={12} md={4}>
              <Box
                sx={{
                  position: 'relative',
                  textAlign: 'center',
                  overflow: 'hidden',
                  '&:hover img': {
                    filter: 'brightness(0.8)',
                  },
                }}
                onClick={() => changelink(0)}
              >
                <img
                  src={require("../images/latte-pour-art.jpg")} 
                  alt="Service 1"
                  style={{ width: '100%', height: '364px', cursor: 'pointer', objectFit: 'cover' }}
                />
                <Typography
                  variant="h5"
                  sx={{
                    position: 'absolute',
                    top: '70%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    color: 'white',
                    padding: '5px',
                    whiteSpace: 'nowrap', // Ensure text stays on one line
                  }}
                >
                  Craft Your Selection
                </Typography>
                <Typography
                  variant="body1"
                  sx={{
                    position: 'absolute',
                    top: '85%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    color: 'white',
                    padding: '5px',
                  }}
                >
                  Explore our menu and customize your perfect cup.
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} md={4}>
              <Box
                sx={{
                  position: 'relative',
                  textAlign: 'center',
                  overflow: 'hidden',
                  '&:hover img': {
                    filter: 'brightness(0.8)',
                  },
                }}
                onClick={() => changelink(1)}
              >
                <img
                  src={require("../images/IMG_8185.png")} 
                  alt="Service 1"
                  style={{ width: '100%', height: '364px', cursor: 'pointer', objectFit: 'cover' }}
                />
                <Typography
                  variant="h5"
                  sx={{
                    position: 'absolute',
                    top: '70%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    color: 'white',
                    padding: '5px',
                    whiteSpace: 'nowrap', // Ensure text stays on one line
                  }}
                >
                  Our Roasted Coffee
                </Typography>
                <Typography
                  variant="body1"
                  sx={{
                    position: 'absolute',
                    top: '85%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    color: 'white',
                    padding: '5px',
                  }}
                >
                  Discover the rich flavors and aromas of our expertly roasted coffee.
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} md={4}>
              <Box
                sx={{
                  position: 'relative',
                  textAlign: 'center',
                  overflow: 'hidden',
                  filter: 'brightness(0.8)',
                  '&:hover img': {
                    filter: 'brightness(0.9)',
                  },
                }}
                onClick={() => changelink(2)}
              >
                <img
                  src={require("../images/machine.jpg")} 
                  alt="Service 1"
                  style={{ width: '100%', height: '364px', cursor: 'pointer', objectFit: 'cover' }}
                />
                <Typography
                  variant="h5"
                  sx={{
                    position: 'absolute',
                    top: '70%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    color: 'white',
                    padding: '5px',
                    whiteSpace: 'nowrap', // Ensure text stays on one line
                  }}
                >
                  Premium Espresso Experience
                </Typography>
                <Typography
                  variant="body1"
                  sx={{
                    position: 'absolute',
                    top: '85%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    color: 'white',
                    padding: '5px',
                  }}
                >
                  Elevate your event with our professional espresso bar.
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Section>

        <Section>
          <div style={{ width: '100%', textAlign: 'center', marginTop: '-80px', display: 'flex', alignItems: 'center', justifyContent: 'center', overflow: 'hidden'}}>
            <Divider style={{ width: '50vw', backgroundColor: '#5C4033', height: '2px' }} />
            <Typography variant="h4" component="h2" sx={{color: '#5C4033', fontSize: '30px', margin: '0 10px', whiteSpace: 'nowrap'}}>
              About Us
            </Typography>
            <Divider style={{ width: '50vw', backgroundColor: '#5C4033', height: '2px' }} />
          </div>
          <Typography variant="h4" align="center" gutterBottom>
          
          </Typography>
          <Grid container spacing={4}>
            <Grid item xs={12} md={6}>
              <Typography variant="body1" paragraph sx={{color: '#5C4033'}} >
                At Perfect Pour Espresso, we pride ourselves on being a family-owned business rooted in the heart of the Bay Area. With a passion for culinary excellence and a commitment to personalized service, we strive to create unforgettable dining experiences for every event. Our team is dedicated to using only the freshest, locally-sourced ingredients to craft our dishes, ensuring that each bite is a testament to our love for great food and our community.
              </Typography>
              <Typography variant="body1" paragraph sx={{color: '#5C4033'}} >
                Our family has been part of the Bay Area for generations, and we bring that deep connection and local knowledge to every event we cater. Whether it's a corporate gathering, a wedding, or an intimate family celebration, we work closely with our clients to design menus that reflect their tastes and preferences, making each event unique and memorable.
              </Typography>
              <Typography variant="body1" paragraph sx={{color: '#5C4033'}} >
                We began with a simple love for food and family. Growing up in the vibrant, diverse food scene of the Bay Area, we were always inspired by the rich culinary traditions and the fresh, high-quality ingredients available right in our backyard. This passion for food brought our family together and became the foundation of our business.
              </Typography>
              <Typography variant="body1" paragraph sx={{color: '#5C4033'}} >
                Our journey started in a small kitchen, where we experimented with recipes handed down through generations, as well as innovative new dishes that captured the spirit of the Bay Area. As word spread about our delicious food and exceptional service, what started as a hobby quickly grew into a full-fledged catering business.
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Image
                src={require("../images/barista.jpg")}
                alt="About Us"
                style={{ width: '100%', height: '570px', cursor: 'pointer', objectFit: 'cover' }}
              />
            </Grid>
          </Grid>
        </Section>
      </Container>
      <Bottom/>
    </div>
  );
};

export default Home;