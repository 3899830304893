import React from 'react';
import { Grid, Typography, Box, Button, Divider } from '@mui/material';
import coffeeImage from '../images/Coffee.jpg'; // Replace with the path to your image
import Bottom from './Bottom';

function OurCoffee() {
  const handleBuyNowClick = () => {
    window.open('https://www.perfectpourespresso.com/', '_blank');
  };

  return (
    <div>
      <Box sx={{ padding: '20px' }}>
        <div style={{ width: '100%', textAlign: 'center', marginTop: '5px', marginBottom: '10px', display: 'flex', alignItems: 'center', justifyContent: 'center', overflow: 'hidden'}}>
          <Divider style={{ width: '50vw', backgroundColor: '#5C4033', height: '2px' }} />
          <Typography variant="h2" component="h2" sx={{color: '#5C4033', margin: '0 10px', whiteSpace: 'nowrap'}}>
            Our Coffee
          </Typography>
          <Divider style={{ width: '50vw', backgroundColor: '#5C4033', height: '2px' }} />
        </div>
        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <Typography variant="body1" paragraph sx={{ color: '#5C4033' }}>
              At Perfect Pour Espresso, we take immense pride in our coffee, which is roasted fresh daily to ensure the utmost quality and flavor. Our commitment to freshness guarantees that every cup of coffee you enjoy is at its peak, bursting with rich aromas and exquisite taste.
            </Typography>
            <Typography variant="body1" paragraph sx={{ color: '#5C4033' }}>
              We source only the finest Arabica beans from South America, a region renowned for its ideal coffee-growing conditions. The unique climate and fertile soil of South America contribute to the exceptional quality of our beans, which are handpicked to ensure that only the best are selected.
            </Typography>
            <Typography variant="body1" paragraph sx={{ color: '#5C4033' }}>
              Our roasting process is a labor of love, combining traditional techniques with modern precision to bring out the full potential of our beans. Each batch is carefully monitored to achieve the perfect roast, enhancing the natural flavors and characteristics of the coffee. Whether you prefer a light, medium, or dark roast, we have a variety of options to suit your taste.
            </Typography>
            <Typography variant="body1" paragraph sx={{ color: '#5C4033' }}>
              In addition to our dedication to quality, we are committed to sustainability and supporting our coffee-growing communities. By partnering with local farmers and cooperatives, we ensure that our coffee is ethically sourced and that the farmers receive fair compensation for their hard work.
            </Typography>
            <Button variant="contained" sx={{ marginTop: '20px', backgroundColor: '#5C4033' }}
              onClick={handleBuyNowClick}
            >
              Buy Now
            </Button>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box
              component="img"
              src={coffeeImage}
              alt="Our Coffee"
              sx={{ width: '100%', height: '475px', cursor: 'pointer', objectFit: 'cover', borderRadius: '8px' }}
            />
          </Grid>
        </Grid>
      </Box>
      <div style={{ marginTop: '50px' }}>
        <Bottom/>
      </div>
      
    </div>
  );
}

export default OurCoffee;
