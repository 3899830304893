import React from 'react';
import { Box, Grid, Typography, Button, Divider } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import CateringImage from '../images/Cafe.jpg';
import HomeUnitImage from '../images/Busi.jpg';
import ConsulationImage from '../images/Counter.jpg';
import Bottom from './Bottom';

function Services() {
  const navigate = useNavigate();

  const handleContactClick = () => {
    navigate('/ContactUs');
  };

  return (
    <div>    
      <Box sx={{ padding: '20px' }}>
        <div style={{ width: '100%', textAlign: 'center', marginTop: '20px', marginBottom: '20px', display: 'flex', alignItems: 'center', justifyContent: 'center', overflow: 'hidden'}}>
          <Divider style={{ width: '50vw', backgroundColor: '#5C4033', height: '2px' }} />
            <Typography variant="h2" component="h2" sx={{color: '#5C4033', fontSize: '40px', margin: '0 10px', whiteSpace: 'nowrap'}}>
              Our Services
            </Typography>
          <Divider style={{ width: '50vw', backgroundColor: '#5C4033', height: '2px' }} />
        </div>
        {/* Catering Section */}
        <Box sx={{ marginBottom: '40px' }}>
          <Grid container spacing={4}>
            <Grid item xs={12} md={6}>
              <Typography variant="h4" sx={{ color: '#5C4033', marginBottom: '20px' }}>
                Catering
              </Typography>
              <Typography variant="body1" paragraph sx={{ color: '#5C4033' }}>
                We provide exceptional catering services for all types of events, from corporate gatherings to weddings and private parties. Our team is dedicated to delivering delicious, handcrafted food and beverages, ensuring that your event is a memorable success.
              </Typography>
              <Typography variant="body1" paragraph sx={{ color: '#5C4033' }}>
                Our catering menu is fully customizable to meet your specific needs and preferences. Contact us to discuss your event and how we can help make it extraordinary.
              </Typography>
              
              <Button 
                variant="contained" 
                sx={{ marginTop: '20px', backgroundColor: '#5C4033' }}
                onClick={handleContactClick}
              >
                Contact Us
              </Button>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box
                component="img"
                src={CateringImage}
                alt="Catering Service"
                sx={{ width: '100%', height: '300px', objectFit: 'cover', borderRadius: '8px', objectPosition: 'center top'}}
              />
            </Grid>
          </Grid>
        </Box>

        {/* Home Unit Sales Section */}
        <Box sx={{ marginBottom: '40px' }}>
          <Grid container spacing={4}>
            <Grid item xs={12} md={6} sx={{ order: { xs: 2, md: 1 } }}>
              <Box
                component="img"
                src={HomeUnitImage}
                alt="Home Unit Sales"
                sx={{ width: '100%', height: '300px', objectFit: 'cover', borderRadius: '8px' }}
              />
            </Grid>
            <Grid item xs={12} md={6} sx={{ order: { xs: 1, md: 2 } }}>
              <Typography variant="h4" sx={{ color: '#5C4033', marginBottom: '20px' }}>
                Machine Sales
              </Typography>
              <Typography variant="body1" paragraph sx={{ color: '#5C4033' }}>
                Bring the café experience to your business or home with our top-of-the-line coffee machines. We offer a range of home units that cater to coffee enthusiasts who appreciate quality and convenience.
              </Typography>
              <Typography variant="body1" paragraph sx={{ color: '#5C4033' }}>
                Explore our selection of machines, each designed to deliver barista-quality coffee with ease. Whether you prefer espresso, cappuccino, or drip coffee, we have the perfect machine for you.
              </Typography>
              <Button 
                variant="contained" 
                sx={{ marginTop: '20px', backgroundColor: '#5C4033' }}
                onClick={handleContactClick}
              >
                Contact Us
              </Button>
            </Grid>
          </Grid>
        </Box>

        {/* Custom Consultation Section */}
        <Box sx={{ marginBottom: '40px' }}>
          <Grid container spacing={4}>
            <Grid item xs={12} md={6}>
              <Typography variant="h4" sx={{ color: '#5C4033', marginBottom: '20px' }}>
                Custom Consultation
              </Typography>
              <Typography variant="body1" paragraph sx={{ color: '#5C4033' }}>
                Interested in purchasing a home coffee unit but not sure which one is right for you? Our custom consultation service is here to help.
              </Typography>
              <Typography variant="body1" paragraph sx={{ color: '#5C4033' }}>
                We offer personalized consultations to help you find the perfect coffee machine that fits your lifestyle and preferences. Our experts will guide you through the options, ensuring you make an informed decision.
              </Typography>
              <Button 
                variant="contained" 
                sx={{ marginTop: '20px', backgroundColor: '#5C4033' }}
                onClick={handleContactClick}
              >
                Contact Us
              </Button>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box
                component="img"
                src={ConsulationImage}
                alt="Custom Consultation"
                sx={{ width: '100%', height: '300px', objectFit: 'cover', borderRadius: '8px' }}
              />
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Bottom/>
    </div>
  );
}

export default Services;
