import React from 'react';
import Context from './Context';

const winDims = () => ({
  height: window.innerHeight,
  width: window.innerWidth,
});

/**
 * @param {object} props
 * @return {object} JSX
 */
function Provider(props) {
  const [global, setGlobal] = React.useState({
    'dimensions': winDims(),
  });
  // source for global context design:
  // https://codeburst.io/beginner-guide-to-react-context-api-with-hook-usestate-4df7c3e08ad
  React.useEffect(() => {
    const handleResize = () => {
      setGlobal({...global, dimensions: winDims()});
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  });
  return (
    <Context.Provider value={{
      ...global,
      currWidth() {
        return this.dimensions.width;
      },
      showAppButtons() {
        return this.dimensions.width > 1100;
      },
      setState: (attr) => {
        setGlobal({...global, ...attr});
      },
    }}>
      {props.children}
    </Context.Provider>
  );
};

export default Provider;
